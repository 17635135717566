const colors = {
    white: "#ffffff",
    black: "#000000",
    grey: "#666666",
    lightgrey: "#999999",
    lightergrey: "#dfdfdf",
    red: "#d64242",
    darkred: "#bc2929",
}

export default colors
