// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-js": () => import("/opt/build/repo/src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-collection-js": () => import("/opt/build/repo/src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-policy-js": () => import("/opt/build/repo/src/templates/policy.js" /* webpackChunkName: "component---src-templates-policy-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cart-js": () => import("/opt/build/repo/src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-straps-js": () => import("/opt/build/repo/src/pages/custom-straps.js" /* webpackChunkName: "component---src-pages-custom-straps-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

